
import ApiService from "@/core/services/ApiService";
import { defineComponent, onMounted, ref, computed } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";

import moment from "moment";
import { date } from "yup";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import FsLightbox from "fslightbox-vue/v3";
import "./styles.css";
interface ProfileDetails {
  father: {
    firstName: string;
    lastName: string;
    phone: string;
  };
  mother: {
    firstName: string;
    lastName: string;
    phone: string;
  };
  email: string;
}
export default defineComponent({
  name: "Kutubee",
  components: { FsLightbox },

  setup() {
    const store = useStore();
    const loading = ref(false);
    const router = useRouter();
    const orderId = ref("");
    const paymentCompleted = ref(false);
    ApiService.get("/konnect/status")
      .then(({ data }) => {
        paymentCompleted.value = data.paymentCompleted;
        orderId.value = data.orderId;
      })
      .catch((e) => {
        paymentCompleted.value = false;

        console.log(e);
      })
      .finally(() => (loading.value = false));
    const { t } = useI18n();
    onMounted(() => {
      setCurrentPageTitle("Kutubee");
    });
    const images = ref<string[]>([
      "media/kutubee/1.png",
      "media/kutubee/2.png",
      "media/kutubee/3.png",
      "media/kutubee/4.png",
    ]);
    const sliderImages = ref<string[]>([
      "media/kutubee/phone-slider-01.svg",
      "media/kutubee/phone-slider-02.svg",
      "media/kutubee/phone-slider-03.svg",
      "media/kutubee/phone-slider-04.svg",
      "media/kutubee/phone-slider-05.svg",
    ]);

    const lightboxToggler = ref(false);

    const openLightbox = (index: number) => {
      // set the toggler to open the lightbox
      lightboxToggler.value = !lightboxToggler.value;
    };
    const profileDetails = ref<ProfileDetails>({
      father: {
        firstName: "",
        lastName: "",
        phone: "",
      },
      mother: {
        firstName: "",
        lastName: "",
        phone: "",
      },
      email: "",
    });
    profileDetails.value = store.getters.currentUser;
    const payUrl = ref<string>("");
    const startPayment = () => {
      loading.value = true;
      ApiService.get("/konnect/initPayment")
        .then(({ data }) => {
          payUrl.value = data.payUrl;
          window.open(data.payUrl, "_blank");
        })
        .catch((e) => console.log(e))
        .finally(() => (loading.value = false));
      console.log(profileDetails.value);
    };
    return {
      t,
      store,
      sliderImages,
      openLightbox,
      lightboxToggler,
      images,
      startPayment,
      loading,
      paymentCompleted,
      orderId,
    };
  },
});
